import clientPaymentPathHelper from "helper/pathHelper/clientPayment";
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper";
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
import PageLayout from "layout/PageLayout";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useGetShowToClientTransactionQuery,
  useUpdateClientLinkMutation,
  useUpdateClientMakeInstantPaymentMutation,
} from "store/services/clientPayments";
import { Browser } from "@capacitor/browser";
import {
  NSDetailLabel,
  NSTitleLabel,
} from "components/BaseLabels";
import PageContentSmaller from "layout/PageContentSmaller";
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation";
import { IonContent, IonFooter, IonIcon, IonModal } from "@ionic/react";
import { alertCircle } from "ionicons/icons";
import {
  PaymentButton,
  PaymentCgu,
  PaymentChoice,
  PaymentStatus,
  PaymentStep,
} from "../Components";
import {
  TRANSACTION_LINK_CLICKED,
  TRANSACTION_STATUS_FAILED,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_SCHEDULED,
  TRANSACTION_STATUS_SUCCESS,
} from "constants/transactionContants";
import "./page.css";
import CustomCheckBox from "components/inputs/CustomCheckBox";
import Spinner from "components/Spinner";

const Steps = ({ currentStep }) => {
  const { t } = usePerPlatformTranslation("client_payment");

  const steps = [
    // { title: "Choix banque", step: 1 },
    { title: t("steps.open_app"), step: 1 },
    { title: t("steps.payment"), step: 2 },
  ];

  return (
    <div class="bg-gray-100 p-4">
      <div class="text-center mb-4">
        <p class="font-bold text-md text-center">{t("steps.next_step")} :</p>
      </div>
      <div class="flex items-center w-full max-w-4xl mx-auto">
        {steps.map((step, index) => {
          return (
            <>
              <PaymentStep
                key={index}
                active={false}
                step={step.step}
                title={step.title}
              />
              {index !== steps.length - 1 && (
                <div class="flex-grow h-1 bg-gray-600"></div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export const PaymentChoicePropositon = ({ uuid, status }) => {
  const { t } = usePerPlatformTranslation("client_payment");
  const [openModal, setOpenModal] = useState(false);
  const [changeBank, setChangeBank] = useState(false);

  const [choice, setChoice] = useState("instant_transfer");
  const history = useHistory();
  const {
    instantTransferPath,
    replayLinkPath,
    updateClientMakeInstantPaymentPath,
  } = clientPaymentPathHelper(uuid);

  const [updateTrigger] = useUpdateClientMakeInstantPaymentMutation();

  const choices = [
    {
      value: "link",
      label: t("waiting_for_payment_link_page.replay_link"),
      description: t("waiting_for_payment_link_page.replay_link_indication"),
      show: status === TRANSACTION_STATUS_FAILED,
      checked: choice === "link",
    },
    {
      value: "instant_transfer",
      label: t("waiting_for_payment_link_page.pay_with_instant_transfer"),
      description: t(
        "waiting_for_payment_link_page.instant_transfer_indication"
      ),
      show: true,
      checked: choice === "instant_transfer",
    },
  ];

  const [trigger] = useUpdateClientLinkMutation();

  const replayLink = async (change = false) => {
    let url = replayLinkPath;
    if (change) {
      url = replayLinkPath + "&change_bank=" + change;
    }

    const updateResult = await trigger({
      url: url,
    });

    if (updateResult.data) {
      const { showClientAcceptPaymentPath } = clientPaymentPathHelper(
        updateResult.data.uuid
      );
      setOpenModal(false);
      window.location = showClientAcceptPaymentPath;
    }
  };

  const validateMyChoice = async () => {
    if (choice === "link") {
      setOpenModal(true);
    } else {
      try {
        const updateResult = await updateTrigger({
          url: updateClientMakeInstantPaymentPath,
        });
      } catch (err) {
      } finally {
        history.replace(instantTransferPath);
      }
    }
  };

  useEffect(() => {
    if (status === TRANSACTION_STATUS_FAILED) {
      setChoice("link");
    }
  }, [status]);

  return (
    <>
      {choices
        .filter((choice) => choice.show)
        .map(({ label, description, value, checked }) => (
          <PaymentChoice
            label={label}
            description={description}
            key={label}
            onClick={() => setChoice(value)}
            checked={checked}
          />
        ))}
      <PaymentButton
        onClick={validateMyChoice}
        label={t("waiting_for_payment_link_page.validate_my_choice")}
      />

      <IonModal isOpen={openModal} onDidDismiss={() => setOpenModal(false)} className="modal-inside-payment">
        <IonContent className="ion-padding modal-content">
          <div>
            <div class="flex justify-center mb-6">
              <img
                src="https://cashlee.eu/wp-content/uploads/2023/01/Cashlee_Logo_RVB_Positif.png"
                alt="Cashlee Logo"
                class="h-14"
              />
            </div>

            <div class="flex flex-col mt-10 mb-6 text-center items-center m-auto">
              <h1 class="text-xl font-bold mb-4 uppercase">
                {t("waiting_for_payment_link_page.replay_link")}{" "}
              </h1>
              <span class="text-sm mb-4">
                {t("waiting_for_payment_link_page.you_will_be_redirected")}
              </span>
              <div className=" flex justify-center items-center">
                <CustomCheckBox
                  checked={changeBank}
                  onChange={() => setChangeBank(!changeBank)}
                />
                <span>
                  {t("waiting_for_payment_link_page.change_selected_bank")}
                </span>
              </div>
            </div>
          </div>
        </IonContent>
        <IonFooter>
          <div class="flex justify-center items-center mb-5">
            <button
              class="bg-[#32ac91] text-white font-bold py-2 px-16 rounded hover:bg-green-700"
              onClick={() => {
                replayLink(changeBank);
              }}
            >
              {t("waiting_for_payment_link_page.replay")}
            </button>
          </div>
        </IonFooter>
      </IonModal>
    </>
  );
};

const ClientWaitingForPaymentLinkPage = () => {
  // useRedirectToInitPayment();
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const { t } = usePerPlatformTranslation("client_payment");

  const uuid = getURLSearchParamsHelper("uuid");
  const { showTransactionToClientPath } = clientPaymentPathHelper(uuid);

  const { data, isFetching } = useGetShowToClientTransactionQuery(
    {
      url: showTransactionToClientPath,
    },
    {
      pollingInterval: refetchInterval,
    }
  );

  const payment_link = data?.data?.payment_link;

  const pay = async () => {
    await Browser.open({ url: payment_link });
  };

  const status = useMemo(() => {
    if (!data?.data?.status) {
      return TRANSACTION_STATUS_SCHEDULED;
    }
    return data.data.status;
  }, [data?.data?.status]);

  // @note For some senarios payment_link could be present even before this page loaded
  // so we will use polling to check for the link.
  useEffect(() => {
    setRefetchInterval(
      [TRANSACTION_STATUS_SUCCESS, TRANSACTION_STATUS_FAILED].includes(status)
        ? 0
        : 3000
    );

    return () => setRefetchInterval(0);
  }, [status]);

  return (
    <PageLayout>
      <PageContentSmaller loading={isFetching && !data?.data?.payment_link}>
        <PageContentWithAppLogo
          pageName="clientPayment"
          body={
            <div className="text-margin">
                {TRANSACTION_STATUS_SUCCESS !== status && (
                 
                    <div className=" block md:flex items-center w-full justify-center my-5 font-bold text-pink-600">
                       <IonIcon
                          slot="icon-only"
                          icon={alertCircle}
                          size="large"
                        />
                      <div className="ml-2 uppercase">
                       
                        {t(
                          "waiting_for_payment_link_page.dont_close_this_page"
                        )}
                      </div>
                    </div>
                )}
              <div className="leading-normal">
                {TRANSACTION_STATUS_SUCCESS === status && (
                  <div className="py-5 mt-5 font-bold text-lg">
                    {t("waiting_for_payment_link_page.well_done")}
                  </div>
                )}

                <div className="space-y-8 py-5">
                  <PaymentStatus status={t("transaction_status." + status)} />

                  {[
                    TRANSACTION_STATUS_PENDING,
                    TRANSACTION_STATUS_FAILED,
                  ].includes(status) && (
                    <PaymentChoicePropositon uuid={uuid} status={status} />
                  )}

                  {[
                    TRANSACTION_LINK_CLICKED,
                    TRANSACTION_STATUS_SCHEDULED,
                  ].includes(status) &&
                    (payment_link ? (
                      <PaymentButton
                        label={"Payer"}
                        disabled={
                          !payment_link || status === TRANSACTION_STATUS_SUCCESS
                        }
                        onClick={pay}
                      />
                    ) : (
                      <Spinner />
                    ))}

                  <PaymentCgu />
                </div>
              </div>
            </div>
          }
        />
      </PageContentSmaller>
    </PageLayout>
  );
};

export default ClientWaitingForPaymentLinkPage;
